import React from 'react';
import { Link } from 'gatsby';

import styles from './Footer.module.css';

export const Footer = () => (
  <footer className={styles.container}>
    <ul>
      <li>
        <Link to="/imprint/">Impressum</Link>
      </li>
      <li>
        <Link to="/privacy/">Datenschutz</Link>
      </li>
    </ul>
  </footer>
);
