import React from 'react';
import { Link } from 'gatsby';

import Portrait from '../Portrait/Portrait';
import styles from './Header.module.css';

const Header = ({ siteTitle }) => (
  <header className={styles.container}>
    <Link to="/" className={styles.imageContainer}>
      <Portrait className={styles.image} />
    </Link>
    <div className={styles.content}>
      <h1>Martin Rädlinger</h1>
      <h3>Freelance Frontend Engineer & Architect</h3>
    </div>
  </header>
);

export default Header;
