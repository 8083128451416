import React from 'react';

import { Layout } from '../components/Layout/Layout';
import { Footer } from '../components/Footer/Footer';

const IndexPage = () => (
  <Layout>
    <h3 />
    <p>
      Ich bin als freiberuflicher Frontend Engineer in der Entwicklung digitaler Produkte auf Basis von Web-Technologien
      tätig.
    </p>
    <p>
      Mit mehr als 14 Jahren Erfahrung plane und programmiere ich Single-Page-Applications (SPA),
      Produkt-Konfiguratoren, Online-Specials, Websites, Mobile-Apps und Human-Machine-Interfaces (HMI) mit Javascript,
      HTML5 und CSS.
    </p>
    <p>
      Als zertifizierter Scrum Master unterstütze ich meine Kunden darüber hinaus mit der Einführung bzw. Umsetzung
      agiler Entwicklungsmethoden.
    </p>
    <Footer />
  </Layout>
);

export default IndexPage;
